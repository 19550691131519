.navbar{
    height: 100px;
    background-color: #003580;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.navContainer{
    width: 100%;
    max-width: 1440px;
    color: white;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.navItems {
    display: flex;
    gap: 50px;
    font-size: 20px;
    font-family: readex;
    cursor: pointer;
}

@font-face {
    font-family: michroma;
    src: url(../../../public/Michroma-Regular.ttf)
  }

  @font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }

.logo {
    font-family: michroma;
    margin: 0px 30px 0px 0px;
    border-bottom: 1px solid white;
}

.logo2 {
    font-family: michroma;
    font-weight: lighter;
    margin: 0px 0px 0px 30px;
}

.groupLogo{
    cursor: pointer;
}

.apply{
    color: white;
    text-decoration: none;
}

@media (max-width: 666px){
    .navItems{
        flex-direction: column;
    }
}