.hero{
    background-image: url("../../images/blue.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    position: relative;
}

@font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }

.heroContainer{
    text-align: center;
    position: absolute;
    top: 150px;
    font-size: 35px;
    display: flex;
}

.heroText {
    padding: 15px;
    border-radius: 20px;
    background-color: white;
    font-family: readex;
}

@media (max-width: 577px){
    .heroText{
        font-size: 24px;
    }
}