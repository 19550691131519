.cTitle{
    text-align: center;
    font-family: readex;
}

.map{
    text-align: center;
}

.contactContainer{
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 30px;
}

.contactInfo{
text-align: center;
}

.contactCards{
    padding: 30px;
    background-color: beige;
    border-radius: 15px;
    font-family: readex;
}

.ico{
    font-size: 44px;
}

@font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }

@media (max-width: 790px){
    .contactContainer{
        flex-direction: column;
    }
}

.map{
    display: flex;
    justify-content: center;
}