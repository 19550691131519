.mainDiv{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.mainContainer{
    max-width: 1440px;
    width: 100%;
    display: flex;
    gap: 100px;
}

.info{
    width: 50%;
}

.image{
    width: 50%;
    display: flex;
    justify-content: center;
    height: 85%;
}

.img{
    width: 100%;
}

.info{
    font-size: 22px;
    font-family: readexlight;
}

@font-face {
    font-family: readexlight;
    src: url(../../../public/ReadexPro-Light.ttf)
  }


button{
    padding: 10px;
    border-radius: 20px;
    width: 150px;
    font-weight: bold;
    background-color: #003580;
    color: white;
    font-size: 20px;
    font-family: readexlight;
}

@media (max-width: 770px){
    .mainContainer{
        flex-direction: column;
        text-align: center;
    }
    .info{
        width: 100%;
    }
    .img{
        margin-left: 250px;
    }
}