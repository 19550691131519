.mainC{
    text-align: center;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(143,142,161,1) 100%);
    
    padding-top: 20px;
    font-family: readex;
}

.containerItems{
    display: flex;
    justify-content: center;
    gap: 300px;
}

.containerItem{
    border: 1px solid black;
    padding: 20px 50px 10px;
    border-radius: 50px;
}

button{
    margin: 30px 0px 30px 0px;
    cursor: pointer;
}

@font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }

@media (max-width: 1080px){
    .containerItems{
        flex-direction: column;
        gap: 20px;
    }
}