.specC{
text-align: center;
width: 100%;
background-color: rgb(253, 249, 242);
margin-top: 30px;
padding-top: 13px;
padding-bottom: 30px;
position: relative;
font-family: readex;
}

.specContainerItem {
}

.specContainerItems{
    display: flex;
    justify-content: center;
    gap: 200px;
}

.mcaInfo{
    position: absolute;
    top: 165px;
    left:580px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

.specTitle{
    cursor: pointer;
    font-family: readex;
}

.specTitle:hover{
    transition: 0.3s;
    color: rgb(70, 110, 243);
}

.creditInfo{
    position: absolute;
    top: 165px;
    left:1100px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

@font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }

@media (max-width: 457px){
    .specContainerItems{
        flex-direction: column;
        gap: 20px;
    }
}