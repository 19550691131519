.contactC{
    background-image: url("../../images/city.jpg");
    height: 30vh;
    color: white;
    text-align: center;
    padding-top: 10px;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(135, 123, 129, 0.7);
    font-family: readex;
}

@font-face {
    font-family: readex;
    src: url(../../../public/ReadexPro-Regular.ttf)
  }